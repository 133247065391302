<template></template>

<script>
import appUtils from "../../utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "AutoLogin",
  data() {
    return {};
  },
  mounted() {
    const token = this.$route.query.token;
    const org_id = this.$route.query.org_id;
    const ws_id = this.$route.query.ws_id;

    if (!token || (!ws_id && !org_id)) {
      this.handleLoginFail();
      return;
    }

    appUtils.setLocalToken(token);
    appUtils.setOrgId(org_id);
    appUtils.setWsId(ws_id);
    appUtils.setLocalTokenExpiration(new Date().getTime() + 3600 * 1000 * 24);
    this.$store.commit("authenticate/setToken", token);
    this.$store.dispatch("authenticate/setLogoutTimer", 3600 * 1000 * 24);

    this.handleAutoLogin();
  },
  methods: {
    async handleAutoLogin() {
      try {
        const response = await this.$rf.getRequest("AuthRequest").goMe();

        const userInfo = response?.data?.data || {};

        const supplierInfo = await this.$store.dispatch(
          "authenticate/handleGetSupplierInfo",
          {
            ws_id: Number(this.$route.query.ws_id),
            org_id: Number(this.$route.query.org_id)
          }
        );

        if (!supplierInfo) {
          this.handleLoginFail(
            this.$t("lbl_you_do_not_have_access_to_the_partner_portal")
          );

          return;
        }

        const newUserInfo = {
          ...userInfo,
          // supplierInfo: userInfo?.ecom_suppliers?.find(
          //   item => item?.org_id === Number(this.$route.query.org_id)
          // )
          supplierInfo
        };
        if (!newUserInfo) {
          this.handleLoginFail();
          return;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("lbl_login_successfully"),
              icon: "CheckIcon",
              variant: "success"
            }
          });

          this.$store.commit("authenticate/setUserLogin", newUserInfo);
          appUtils.setLocalUser(JSON.stringify(newUserInfo));
          this.$router.replace({ name: "Marketplace" }).catch(error => {});
        }
      } catch (error) {
        console.log(error);
        this.handleLoginFail();
      }
    },
    handleLoginFail(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message || this.$t("lbl_something_went_wrong_when_login"),
          icon: "AlertTriangleIcon",
          variant: "danger"
        }
      });
      appUtils.removeLocalUser();
      appUtils.removeLocalToken();

      this.$router.push({ path: "/welcome" });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>